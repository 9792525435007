
import { Col } from "../../basic";
import { TransformedCoverSheetData, CoverSheetApplicant } from "@/data/coversheet";

type Props = {
  primary: TransformedCoverSheetData["primaryApplicant"];
  joint: TransformedCoverSheetData["jointApplicants"];
};

export function BorrowerSection({ primary, joint }: Props): JSX.Element {
  return (
    <div className="grid-2 column-gap-sm">
      <Col gap="xs" className="light-gray-panel">
        <span className="text-xs color-grey-50">Primary Applicant</span>
        <h2>{primary.fullname}</h2>
      </Col>
      {joint.length > 0 && joint.map((applicant: CoverSheetApplicant, index: number) => (
          <Col key={index} gap="xs" className="light-gray-panel">
          <span className="text-xs color-grey-50">Joint Applicant</span>
          <h2>{applicant.fullname}</h2>
        </Col>
      ))}
    </div>
  );
}
