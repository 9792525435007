import { TogglePanel, Col, Table } from "../../basic";
import { formatCurrency } from "@/utils/formatters";
import * as O from 'fp-ts/Option';
import { BankAccount, BankTransaction } from "@/data/coversheet";

type Props = {
  account: BankAccount;
};

function TransactionTable({ transactions, title }: { transactions: BankTransaction[], title: string }) {
  return (
    <Col gap="sm">
      <div className="text-smd">{title}</div>
      <Table
        className="earning-items"
        data={transactions}
        columns={[
          {
            columnKey: "description",
            header: <div className="text-left">Description</div>,
            view: (item) => <div className="text-left">{item.description}</div>
          },
          {
            columnKey: "transactionDate",
            header: <div className="text-right">Date</div>,
            view: (item) => <div className="text-right">{item.transactionDate}</div>
          },
          {
            columnKey: "amount",
            header: <div className="text-right">Amount</div>,
            view: (item) => <div className="text-right">{formatCurrency(item.amount)}</div>
          }
        ]}
        itemKey={(item) => `${item.transactionDate}-${item.description}`}
        pageInfo={O.none}
        isLoading={false}
      />
    </Col>
  );
}

export function BankAccountCard({ account }: Props): JSX.Element {
  const mostRecentStatement = account.statements[0];
  
  return (
    <TogglePanel 
      title={`${account.bankName} - ${account.accountType}`}
      subTitle={`Account #${account.accountNumber} | Balance: ${mostRecentStatement.endingBalance !== null ? formatCurrency(mostRecentStatement.endingBalance) : 'N/A'}`}
    >
      <Col gap="md">
        <Col gap="sm">
          <h3>Account Details</h3>
          <div className="text-left">Most Recent Statement Date: {account.mostRecentStatementDate}</div>
        </Col>

        {mostRecentStatement.largeTransactions.length > 0 && (
          <TransactionTable 
            transactions={mostRecentStatement.largeTransactions}
            title="Large Transactions"
          />
        )}
        
        {mostRecentStatement.irsTransactions.length > 0 && (
          <TransactionTable 
            transactions={mostRecentStatement.irsTransactions}
            title="IRS Transactions"
          />
        )}
        
        {mostRecentStatement.buyNowPayLaterTransactions.length > 0 && (
          <TransactionTable 
            transactions={mostRecentStatement.buyNowPayLaterTransactions}
            title="Buy Now Pay Later Transactions"
          />
        )}
      </Col>
    </TogglePanel>
  );
}
