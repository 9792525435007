import { TogglePanel, Col, Table } from "../../basic";
import { CoverSheetPaystub } from "@/data/coversheet";
import { formatCurrency, formatDate } from "@/utils/formatters";
import * as O from 'fp-ts/Option';


type Props = {
  paystub: CoverSheetPaystub;
};

export function PaystubCard({ paystub }: Props): JSX.Element {
  return (
    <TogglePanel
      title={`Pay Date: ${formatDate(paystub.payDate)}`}
      subTitle={`Pay Period: ${formatDate(paystub.payPeriodStartDate)} - ${formatDate(paystub.payPeriodEndDate)}`}
      toggleHeaderClassName="paystub-card"
    >
          <Col gap="sm" className="light-gray-panel">
              <h4>Summary</h4>
              <div className="flex-grid">
                  {paystub.regularIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Regular Income</span><br /> {formatCurrency(paystub.regularIncome)}</div>}
                  {paystub.overtimeIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Overtime Income</span><br /> {formatCurrency(paystub.overtimeIncome)}</div>}
                  {paystub.commissionIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Commission Income</span><br /> {formatCurrency(paystub.commissionIncome)}</div>}
                  {paystub.bonusIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Bonus Income</span><br /> {formatCurrency(paystub.bonusIncome)}</div>}
                  {paystub.tipIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Tip Income</span><br /> {formatCurrency(paystub.tipIncome)}</div>}
                  {paystub.otherIncome > 0 && <div className="flex-grid-item"><span className="income-category-label">Other Income</span><br /> {formatCurrency(paystub.otherIncome)}</div>}
              </div>
        {paystub.earningItems.length > 0 && (
          <>
            <h4>Earning Items</h4>
            <Table
              className="earning-items"
              data={paystub.earningItems}
              columns={[
                {
                  columnKey: "description",
                  header: <div className="text-left">Description</div>,
                  view: (item) => <div className="text-left">{item.description}</div>
                },
                {
                  columnKey: "category",
                  header: <div className="text-left">Category</div>,
                  view: (item) => <div className="text-left">{item.category}</div>
                },
                {
                  columnKey: "hours",
                    header: <div className="text-right">Hours</div>,
                    headerContainerClassName: "align-right w-100",
                  view: (item) => <div className="text-right">{item.hours}</div>
                },
                {
                  columnKey: "amount",
                    header: <div className="text-right">Amount</div>,
                    headerContainerClassName: "align-right w-100",
                  view: (item) => <div className="text-right">{formatCurrency(item.amount)}</div>
                },
                {
                  columnKey: "ytdHours",
                    header: <div className="text-right">YTD Hours</div>,
                    headerContainerClassName: "align-right w-100",
                  view: (item) => <div className="text-right">{item.yearToDateHours}</div>
                },
                {
                  columnKey: "ytdAmount",
                    header: <div className="text-right">YTD Amount</div>,
                    headerContainerClassName: "align-right w-100",
                  view: (item) => <div className="text-right">{formatCurrency(item.yearToDateAmount)}</div>
                }
              ]}
              itemKey={(item) => `${item.description}-${item.category}`}
              pageInfo={O.none}
              isLoading={false}
            />
          </>
        )}
      </Col>
    </TogglePanel>
  );
}
