import "@/assets/styles/Wizard/wizard.css";
import homePageAppCatcherLogo from "@/assets/images/home-page-app-catcher-logo.png";

import * as Address from "@/components/Address";
import * as ContactInformation from "@/components/ContactInformation";
import * as FundsAndCoApplicant from "@/components/FundsAndCoApplicant";
import * as IncomeInformation from "@/components/IncomeInformation";
import * as MaritalAndResidency from "@/components/MaritalAndResidency";
import * as PropertyInformation from "@/components/PropertyInformation";
import * as ReferralInformation from "@/components/ReferralInformation";
import * as RetirementAndObligations from "@/components/RetirementAndObligations";
import * as SsnAndConsent from "@/components/SsnAndConsent";

import { ChildProps } from "@/utils/reducerWithEffect";
import {
  Action,
  AddressAction,
  AddressCompleted,
  BackOfficeSaveSurveyRequested,
  CoApplicantSameAddressPrimaryInputToggled,
  ContactInformationAction,
  ContactInformationCompleted,
  FundsAndCoApplicantAction,
  FundsAndCoApplicantCompleted,
  IncomeInformationAction,
  IncomeInformationCompleted,
  MaritalAndResidencyAction,
  MaritalAndResidencyCompleted,
  PropertyInformationAction,
  PropertyInformationCompleted,
  ReferralInformationAction,
  ReferralInformationCompleted,
  RetirementAndObligationsAction,
  RetirementAndObligationsCompleted,
  SsnAndConsentAction,
  SsnAndConsentCompleted,
  StepCanceled,
} from "./action";
import { Model, surveyTipId } from "./model";

import { Button, Col, Icon, Label, Row, TogglePanel } from "@/components/basic";
import {
  SurveyProgress,
  SurveyStepProgress,
} from "@/components/SurveyProgress";
import { SurveyPayload } from "@/data/payload";
import {
  ApplicantType,
  clearSurveyFromLocalStorage,
  SurveyFlowPayload,
  toSurveyPayload,
  withSsnAndDateOfBirth,
} from "@/data/surveyFlowPayload";
import { OrdSurveyTips } from "@/data/surveyTips";
import { deferredToOption, isResolved } from "@/utils/deferred";
import * as A from "fp-ts/Array";
import * as E from "fp-ts/Either";
import { constant, constFalse, flow, identity, pipe } from "fp-ts/function";
import { sequenceT } from "fp-ts/lib/Apply";
import * as O from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { ViewResolver, ViewResolverProps } from "@/utils/viewResolver";
import { LocalStoragetKeys } from "@/utils/localstorage";
import { LoaderMessages, LoaderView } from "../basic/Loaders";
import { useRights } from "@/utils/use-rights";
import { Started } from "@/utils/asyncOperationStatus";
import { FlashAdder } from "@/utils/page-types";
import { DateTime } from "luxon";
import { RouterContext } from "@/utils/router-context";
import { isEqual } from "lodash-es";
import * as Notes from "@/components/Notes";
import { formatPhoneNumber } from "@/utils/formatters";

export type Props = ChildProps<Model, Action> & {
  onFinish: (payload: SurveyPayload) => void;
  referringLoanOfficerId: Option<number>;
  flashAdder: FlashAdder;
  clientLogoUrl?: string;
};

const titles: Record<ApplicantType, Record<string, string>> = {
  PrimaryApplicant: {
    CurrentAddress: "What best describes your current living arrangement?",
    PreviousAddress: "We need your previous address",
  },
  CoApplicant: {
    CurrentAddress:
      "What best describes your co-applicant's current living arrangement?",
    PreviousAddress: "We need your co-applicant's previous address",
  },
};

const contactInformationTitles: Record<ApplicantType, string> = {
  PrimaryApplicant:
    "We need some initial bits of information before we get started.",
  CoApplicant: "Let's grab the personal details of your co-applicant.",
};

const maritalAndResidencyTitles: Record<ApplicantType, string> = {
  PrimaryApplicant: "Final few questions regarding your personal information!",
  CoApplicant:
    "Final few questions regarding your co-applicant's personal information!",
};

const incomeInformationTitles: Record<ApplicantType, string> = {
  PrimaryApplicant:
    "Personal information complete! Let's run through your financial information.",
  CoApplicant:
    "Personal information complete! Let's run through your co-applicant's financial information.",
};

const retirementAndObligationsTitles: Record<ApplicantType, string> = {
  PrimaryApplicant: "Nearly there! Last bits of financial information?",
  CoApplicant: "Please indicate your co-applicant's financial information.",
};

type StepContainerProps = {
  header?: string;
  payload: SurveyFlowPayload;
  children: ReactNode;
};

type ViewWrapperProps = {
  children: React.ReactNode;
};

function StepContainer(props: StepContainerProps): JSX.Element {
  return (
    <Col padding="xl" gap="xl">
      <SurveyProgress
        personal={pipe(
          sequenceT(O.Apply)(
            props.payload.primaryApplicantPayload.contactInformation,
            props.payload.primaryApplicantPayload.addresses,
            props.payload.primaryApplicantPayload.maritalAndResidency,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.primaryApplicantPayload.contactInformation,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        financial={pipe(
          sequenceT(O.Apply)(
            props.payload.primaryApplicantPayload.incomeInformation,
            props.payload.primaryApplicantPayload.retirementAndObligations,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.primaryApplicantPayload.incomeInformation,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        mortgage={pipe(
          sequenceT(O.Apply)(
            props.payload.fundsAndCoApplicant,
            props.payload.referralInformation,
          ),
          O.map((): SurveyStepProgress => "Completed"),
          O.alt(() =>
            pipe(
              props.payload.fundsAndCoApplicant,
              O.map((): SurveyStepProgress => "InProgress"),
            ),
          ),
          O.getOrElse((): SurveyStepProgress => "Empty"),
        )}
        verification={pipe(
          props.payload.primaryApplicantPayload.ssnAndDateOfBirthPayload,
          O.fold(
            (): SurveyStepProgress => "Empty",
            (): SurveyStepProgress => "Completed",
          ),
        )}
        documentation="Empty"
      />
      {props.header && <div className="survey-flow-header">{props.header}</div>}
      {props.children}
    </Col>
  );
}

function StepView(props: Props): JSX.Element {
  const { model, dispatch } = props;

  switch (model.currentStep.type) {
    case "PropertyInformation":
      return (
        <PropertyInformation.View
          model={model.currentStep.propertyInformationModel}
          dispatch={flow(PropertyInformationAction, dispatch)}
          onCompleted={flow(PropertyInformationCompleted, dispatch)}
        />
      );

    case "ContactInformation":
      return (
        <StepContainer
          header={contactInformationTitles[model.currentStep.applicantType]}
          payload={model.payload}
        >
          <ContactInformation.View
            model={model.currentStep.contactInformationModel}
            dispatch={flow(ContactInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "Address":
      {
        const header = pipe(model.currentStep, (v) => {
          const stepIndex =
            v.addressIndex == 0 ? "CurrentAddress" : "PreviousAddress";
          return titles[v.applicantType][stepIndex];
        });
        return (
          <StepContainer header={header} payload={model.payload}>
            <Address.View
              model={model.currentStep.addressModel}
              sameAddressAsApplicant={sameAsPrimaryAddress(model)}
              onCoApplicantSameAddressPrimaryInputToggled={pipe(
                model.currentStep.applicantType,
                O.fromPredicate((apTpye) => apTpye == "CoApplicant"),
                O.map(() =>
                  flow(CoApplicantSameAddressPrimaryInputToggled, dispatch),
                ),
              )}
              dispatch={flow(AddressAction, dispatch)}
            />
          </StepContainer>
        );
      }
      break;

    case "MaritalAndResidency":
      return (
        <StepContainer
          header={maritalAndResidencyTitles[model.currentStep.applicantType]}
          payload={model.payload}
        >
          <MaritalAndResidency.View
            applicantType={model.currentStep.applicantType}
            model={model.currentStep.maritalAndResidencyModel}
            dispatch={flow(MaritalAndResidencyAction, dispatch)}
          />
        </StepContainer>
      );

    case "IncomeInformation":
      return (
        <StepContainer
          header={incomeInformationTitles[model.currentStep.applicantType]}
          payload={model.payload}
        >
          <IncomeInformation.View
            model={model.currentStep.incomeInformationModel}
            dispatch={flow(IncomeInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "FundsAndCoApplicant":
      return (
        <StepContainer
          header="Great! Now let’s run through some of the mortgage related information."
          payload={model.payload}
        >
          <FundsAndCoApplicant.View
            model={model.currentStep.fundsAndCoApplicantModel}
            dispatch={flow(FundsAndCoApplicantAction, dispatch)}
          />
        </StepContainer>
      );

    case "RetirementAndObligations":
      return (
        <StepContainer
          header={
            retirementAndObligationsTitles[model.currentStep.applicantType]
          }
          payload={model.payload}
        >
          <RetirementAndObligations.View
            applicantType={model.currentStep.applicantType}
            model={model.currentStep.retirementAndObligationsModel}
            dispatch={flow(RetirementAndObligationsAction, dispatch)}
          />
        </StepContainer>
      );

    case "ReferralInformation":
      return (
        <StepContainer
          header="Thanks! Before we move onto the final steps."
          payload={model.payload}
        >
          <ReferralInformation.View
            model={model.currentStep.referralInformationModel}
            dispatch={flow(ReferralInformationAction, dispatch)}
          />
        </StepContainer>
      );

    case "SsnAndConsent":
      return (
        <StepContainer payload={model.payload}>
          <SsnAndConsent.View
            model={model.currentStep.ssnAndConsentModel}
            dispatch={flow(SsnAndConsentAction, dispatch)}
          />
        </StepContainer>
      );
  }
}

function ViewWrapper(props: ViewWrapperProps): JSX.Element {
  const desktopView = <Row grow={1}>{props.children}</Row>;

  const mobileView = <Col grow={1}>{props.children}</Col>;

  const viewSetup: ViewResolverProps<JSX.Element> = {
    viewModes: [
      ["Default"],
      [
        "Mobile-Portrait",
        "Tablet-Portrait",
        "Mobile-Landscape",
        "Tablet-Landscape",
      ],
    ],
    resolvedContent: [desktopView, mobileView],
  };
  return ViewResolver(viewSetup);
}

export function View(props: Props): JSX.Element {
  const { model, flashAdder } = props;

  const { router } = useContext(RouterContext);

  const [isSigningIn, setIsSigningin] = useState(false);

  useEffect(() => {
    const isSaveDone = isResolved(model.savedSurvey);

    const savedSureyResult = pipe(
      deferredToOption(model.savedSurvey),
      O.chain(O.fromEither),
      O.map((v) => v),
    );

    if (isSaveDone && O.isSome(savedSureyResult)) {
      const email = pipe(
        model.payload.primaryApplicantPayload.contactInformation,
        O.fold(constant("unknown"), (v) => v.email),
      );

      if (savedSureyResult.value.isExistingSurvey) {
        flashAdder({
          message: `An active application already exists for ${email}.`,
          pushed: DateTime.now(),
          type: "info",
        });
      }

      clearSurveyFromLocalStorage();
      const timeoutId = setTimeout(() => {
        router.navigate(`summary/${savedSureyResult.value.applicationId}`);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    model.payload.primaryApplicantPayload.contactInformation,
    model.savedSurvey,
    model.savedSurvey.status,
    flashAdder,
    router,
  ]);

  const surveyTips = useMemo(() => {
    return pipe(
      model.tips,
      deferredToOption,
      O.chain(O.fromEither),
      O.fold(
        () => [],
        flow(
          A.filter(
            ({ pageIdentifier }) =>
              surveyTipId(model.currentStep) === pageIdentifier,
          ),
          A.sort(OrdSurveyTips),
        ),
      ),
    );
  }, [model.currentStep, model.tips]);

  useEffect(() => {
    const isSigningIn = localStorage.getItem(
      LocalStoragetKeys.BorrowerSignupInProgress,
    );
    setIsSigningin(pipe(isSigningIn, O.fromNullable, O.isSome));
  }, []);

  const surveyContent = (
    <>
      {pipe(
        model.isLoanOfficerInviting,
        O.fold(
          () => (
            <Row
              background="white"
              className="menu-bar"
              padding="sm"
              alignVertical="center"
              alignHorizontal="space-between"
            >
              <Col>
                {props.clientLogoUrl ? (
                  <>
                    <img
                      src={props.clientLogoUrl}
                      style={{ maxHeight: "4em", maxWidth: "10em" }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
              <Col>
                <img src={homePageAppCatcherLogo}></img>
              </Col>
            </Row>
          ),
          () => <></>,
        ),
      )}
      <ViewWrapper>
        <Col grow={4} basis="0">
          <Col
            grow={1}
            className={ViewResolver({
              viewModes: [
                ["Default"],
                [
                  "Tablet-Landscape",
                  "Tablet-Portrait",
                  "Mobile-Portrait",
                  "Mobile-Landscape",
                ],
              ],
              resolvedContent: [
                `suvery-page-flow survey-content ${O.isNone(model.isLoanOfficerInviting) ? "borrower-survey-flow" : ""}`,
                `suvery-page-flow ${O.isNone(model.isLoanOfficerInviting) ? "borrower-survey-flow" : ""}`,
              ],
            })}
            alignHorizontal="stretch"
            background="grey10"
          >
            <StepView {...props} />
          </Col>
          <Navbar {...props} />
        </Col>
        <Col grow={1} basis="10vw">
          <Col
            padding="sm"
            gap="sm"
            grow={1}
            className={`tips tips-content-flow ${O.isNone(model.isLoanOfficerInviting) ? "borrower-survey-flow" : ""}`}
            basis={ViewResolver({
              viewModes: [
                ["Default"],
                [
                  "Mobile-Portrait",
                  "Tablet-Portrait",
                  "Mobile-Landscape",
                  "Tablet-Landscape",
                ],
              ],
              resolvedContent: ["0", "auto"],
            })}
          >
            {pipe(
              model.isLoanOfficerInviting,
              O.fold(constant(<></>), () => (
                <>
                  <Col>
                    <Notes.View
                      field={model.payload.loanOfficerNote}
                      onChange={(value) =>
                        (model.payload.loanOfficerNote = O.some(value))
                      }
                    ></Notes.View>
                  </Col>
                  <hr className="grey-hr"></hr>
                </>
              )),
            )}

            <Col gap="sm">
              {surveyTips
                .filter((v) => {
                  switch (model.currentStep.type) {
                    case "RetirementAndObligations":
                    case "Address":
                    case "IncomeInformation":
                    case "ContactInformation":
                    case "MaritalAndResidency": {
                      const _isForCoApplicant = pipe(
                        v.isForCoApplicant,
                        O.getOrElse(() => false),
                      );
                      return model.currentStep.applicantType == "CoApplicant"
                        ? _isForCoApplicant
                        : !_isForCoApplicant;
                    }

                    case "FundsAndCoApplicant":
                    case "PropertyInformation":
                    case "ReferralInformation":
                    case "SsnAndConsent":
                      return true;
                  }
                })
                .map((tip) => (
                  <Col
                    key={tip.pageIdentifier + tip.pageTipId}
                    padding="sm"
                    gap="sm"
                    className="container"
                    alignHorizontal="left"
                  >
                    <Row
                      padding="xxxs"
                      gap="xxxs"
                      className="tip-question"
                      alignVertical="center"
                    >
                      <Icon type="lightbulb" />
                      <span>{tip.prompt}</span>
                    </Row>
                    <Row gap="xs">
                      <img height={32} src={tip.avatarUrl} />
                      <Label>
                        <b>{tip.title}</b>
                      </Label>
                    </Row>
                    <Label>{tip.content}</Label>
                  </Col>
                ))}
            </Col>
          </Col>
          {pipe(
            model.isLoanOfficerInviting,
            O.fold(
              () => (
                <>
                  {pipe(
                    model.referralLoanOfficerInfo,
                    O.fold(constant(<></>), (referringLoanOfficerInfo) => (
                      <Col className="loan-officer-info">
                        <TogglePanel
                          title={referringLoanOfficerInfo.fullname || "--"}
                          subTitle="Loan Officer"
                          toggleHeaderClassName="lo-info-toggle"
                          showIcon={true}
                        >
                          <Col gap="sm">
                            <Row alignVertical="center" gap="sm">
                              <Col
                                className="icon-wrapper"
                                alignHorizontal="center"
                                alignVertical="center"
                              >
                                <i className="fa-regular fa-envelope"></i>
                              </Col>
                              <Label className="text-xs black">
                                {referringLoanOfficerInfo.email || "-"}
                              </Label>
                            </Row>
                            <Row alignVertical="center" gap="sm">
                              <Col
                                className="icon-wrapper"
                                alignHorizontal="center"
                                alignVertical="center"
                              >
                                <i className="fa-solid fa-phone"></i>
                              </Col>
                              <Label className="text-xs black">
                                {formatPhoneNumber(
                                  referringLoanOfficerInfo.phoneNumber,
                                )}
                              </Label>
                            </Row>
                            <Row alignVertical="center" gap="sm">
                              <Col
                                className="icon-wrapper"
                                alignHorizontal="center"
                                alignVertical="center"
                              >
                                <i className="fa-solid fa-location-dot"></i>
                              </Col>
                              {/* Add address when available in response */}
                              <Label className="text-xs black">-</Label>
                            </Row>
                          </Col>
                        </TogglePanel>
                      </Col>
                    )),
                  )}
                </>
              ),
              constant(<></>),
            ),
          )}
        </Col>
      </ViewWrapper>
    </>
  );
  //TODO: Validate navigation on survey route(wait till user is resolved)
  return (
    <>
      {isSigningIn ? (
        <LoaderView
          loaderMessage={LoaderMessages.surveyInit}
          showLoadingIcon={true}
        ></LoaderView>
      ) : (
        <>{surveyContent}</>
      )}
    </>
  );
}

export function Navbar(props: Props): JSX.Element {
  const { model, dispatch } = props;

  const { isBackOfficeUser } = useRights();
  const stepCompletedAction: Option<Action> = useMemo(() => {
    switch (model.currentStep.type) {
      case "PropertyInformation":
        return O.none;
      case "ContactInformation":
        return pipe(
          ContactInformation.result(model.currentStep.contactInformationModel),
          O.fromEither,
          O.map(ContactInformationCompleted(model.currentStep.applicantType)),
        );
      case "Address": {
        return pipe(
          Address.result(
            model.currentStep.addressIndex,
            model.currentStep.addressModel,
          ),
          O.fromEither,
          O.map(
            AddressCompleted(
              model.currentStep.applicantType,
              model.currentStep.addressIndex,
            ),
          ),
        );
      }
      case "MaritalAndResidency":
        return pipe(
          MaritalAndResidency.result(
            model.currentStep.maritalAndResidencyModel,
          ),
          O.fromEither,
          O.map(MaritalAndResidencyCompleted(model.currentStep.applicantType)),
        );
      case "FundsAndCoApplicant":
        return pipe(
          FundsAndCoApplicant.result(
            model.currentStep.fundsAndCoApplicantModel,
          ),
          O.fromEither,
          O.map(FundsAndCoApplicantCompleted),
        );
      case "RetirementAndObligations":
        return pipe(
          RetirementAndObligations.result(
            model.currentStep.retirementAndObligationsModel,
          ),
          RetirementAndObligationsCompleted(model.currentStep.applicantType),
          O.some,
        );
      case "IncomeInformation":
        return pipe(
          IncomeInformation.result(model.currentStep.incomeInformationModel),
          IncomeInformationCompleted(model.currentStep.applicantType),
          O.some,
        );
      case "ReferralInformation":
        return pipe(
          ReferralInformation.result(
            model.currentStep.referralInformationModel,
          ),
          O.fromEither,
          O.map(ReferralInformationCompleted),
        );
      case "SsnAndConsent": {
        return pipe(
          SsnAndConsent.result(model.currentStep.ssnAndConsentModel),
          O.fromPredicate(([verifyModel, consents, consentErrors]) => {
            let isFormValid = E.isRight(consents) && E.isRight(verifyModel);

            isFormValid = isFormValid && consentErrors.left.length == 0;

            return isFormValid;
          }),
          O.flatMap(
            ([
              verifyModel,
              consents,
              ,
              providedConsentForElectronicBusiness,
            ]) => {
              if (E.isRight(verifyModel) && E.isRight(consents)) {
                const { primaryApplicant, coApplicant } = verifyModel.right;
                return O.some(
                  SsnAndConsentCompleted(
                    primaryApplicant,
                    coApplicant,
                    consents.right,
                    providedConsentForElectronicBusiness,
                  ),
                );
              } else {
                return O.none;
              }
            },
          ),
        );
      }
    }
  }, [model.currentStep]);

  const stepCanceledAction = useMemo(
    () => pipe(A.last(model.completedSteps), O.map(StepCanceled)),
    [model.completedSteps],
  );

  // for the property information step designs don't have the continue/back buttons'
  return model.currentStep.type === "PropertyInformation" ? (
    <></>
  ) : (
    <Row
      gap="xs"
      padding="xs"
      alignHorizontal="space-between"
      background="white"
    >
      <Button
        type="secondary"
        onClick={pipe(
          stepCanceledAction,
          O.map((action) => () => dispatch(action)),
        )}
      >
        <i className="fa-solid fa-arrow-left ml-right-xxs" /> Go back
      </Button>
      {model.currentStep.type === "SsnAndConsent" ? (
        <Button
          type="primary"
          derferredStatus={model.savedSurvey}
          onClick={pipe(
            O.of((action: Action) => (payload: SurveyPayload) => () => {
              dispatch(action);
              pipe(isBackOfficeUser, (v) => (O.isSome(v) ? v.value : false))
                ? flow(
                    Started,
                    BackOfficeSaveSurveyRequested(payload),
                    props.dispatch,
                  )()
                : props.onFinish(payload);
            }),
            O.ap(stepCompletedAction),
            O.ap(
              pipe(
                SsnAndConsent.result(model.currentStep.ssnAndConsentModel),
                O.fromPredicate(([verifyModel, consents, consentMethod]) => {
                  let isFormValid =
                    E.isRight(consents) && E.isRight(verifyModel);

                  isFormValid = isFormValid && consentMethod.left.length == 0;

                  return isFormValid;
                }),
                O.flatMap(
                  ([
                    surveyModel,
                    consents,
                    ,
                    providedConsentForElectronicBusiness,
                  ]) => {
                    if (E.isRight(surveyModel) && E.isRight(consents)) {
                      const { primaryApplicant, coApplicant } =
                        surveyModel.right;
                      return O.some(
                        withSsnAndDateOfBirth(
                          primaryApplicant,
                          coApplicant,
                          consents.right,
                          pipe(
                            model.isLoanOfficerInviting,
                            O.fold(constFalse, identity),
                          ),
                          providedConsentForElectronicBusiness,
                        )(model.payload),
                      );
                    } else {
                      return O.none;
                    }
                  },
                ),

                // discarding the possible error of converting to survey payload
                // but it could be used to identify the survey step responsible for the missing data that caused the error
                // if we want to indicate it as an error message to the user
                // or navigate backe to that step
                O.chain(
                  flow(
                    toSurveyPayload(
                      model.dateTime,
                      props.referringLoanOfficerId,
                    ),
                    O.fromEither,
                  ),
                ),
              ),
            ),
          )}
        >
          {pipe(
            model.isLoanOfficerInviting,
            O.fold(
              () => (
                <>
                  Submit <i className="fa-solid fa-arrow-right ml-left-xxs" />
                </>
              ),
              (v) =>
                v ? (
                  <>
                    Send Invite and Continue{" "}
                    <i className="fa-solid fa-arrow-right ml-left-xxs" />
                  </>
                ) : (
                  <>
                    Submit <i className="fa-solid fa-arrow-right ml-left-xxs" />
                  </>
                ),
            ),
          )}
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={pipe(
            stepCompletedAction,
            O.map((action) => () => dispatch(action)),
          )}
        >
          Continue <i className="fa-solid fa-arrow-right ml-left-xxs" />
        </Button>
      )}
    </Row>
  );
}

const sameAsPrimaryAddress = (model: Model) =>
  pipe(
    model.payload.coApplicantPayload,
    O.fold(
      () => O.none,
      (payload) =>
        O.some(
          isEqual(
            model.payload.primaryApplicantPayload.addresses,
            payload.addresses,
          ),
        ),
    ),
  );

// type SubmitSurveyButtonProps = {
//   payload: SurveyFlowPayload;
//   onFinish: (payload: SurveyPayload) => void;
// }

// function SubmitSurveyButton(props: )
