import { BounceLoader } from "react-spinners";
import { Button, Col, Label } from "../basic";
import { Model } from "./model";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { BorrowerSection } from "./components/BorrowerSection";
import { IncomeSection } from "./components/IncomeSection";
import { BankAccountCard } from "./components/BankAccountCard";
import { DocumentsSection } from "./components/DocumentsSection";
import { TransformedCoverSheetData } from "@/data/coversheet";

type Props = {
  model: Model;
  onClose: () => void;
};

function LoadingView() {
  return (
    <Col alignHorizontal="center">
      {(
        <Col alignVertical="center" alignHorizontal="center" gap="xs">
          <BounceLoader color="#2A2250" />
          <Label className="text-md text-mbold action-70">
            Fetching application summary...
          </Label>
        </Col>
      )}
    </Col>
  );
}

function ErrorView() {
  return (
    <Col alignHorizontal="center">
      {(
        <Col alignVertical="center" alignHorizontal="center" gap="xs">
          <Label className="text-lg text-mbold action-70">
            Failed to load the application summary!
          </Label>
        </Col>
      )}
    </Col>
  );
}

export function View(props: Props): JSX.Element {
  const { model } = props;
  
  switch (model.summaryContent.status) {
    case "NotStarted":
    case "InProgress":
    case "Updating":
      return <LoadingView />;
    
    case "Resolved": {
      const { value } = model.summaryContent;
      if (E.isRight(value)) {
        const data = value.right as TransformedCoverSheetData;
        return (
          <Col gap="md">
            <BorrowerSection 
              primary={data.primaryApplicant}
              joint={data.jointApplicants}
                />
                <div className="text-lg">Income</div>
            <IncomeSection 
              primary={data.primaryApplicant}
              joint={data.jointApplicants}
                />
                <div className="text-lg">Assets</div>
            <Col gap="sm" alignVertical="top">
              <h3>Bank Accounts</h3>
              {data.primaryApplicant.bankAccounts.map((account, index) => (
                <BankAccountCard key={`primary-${index}`} account={account} />
              ))}
              {data.jointApplicants.map((applicant, applicantIndex) => 
                applicant.bankAccounts.map((account, accountIndex) => (
                  <BankAccountCard 
                    key={`joint-${applicantIndex}-${accountIndex}`} 
                    account={account}
                  />
                ))
              )}
                </Col>

                <div className="text-lg">Documents</div>
            <DocumentsSection documents={data.documents} />
            <Button type="primary" onClick={O.some(() => props.onClose())}>
              Close
            </Button>
          </Col>
        );
      }
      return <ErrorView />;
    }
  }
}
